import moment from 'moment';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';

import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { onCreateAvalabityException , onCreateUserPackages, onDeclineUserPackages } from '../../util/api';
import * as log from '../../util/log';
import {
  updatedEntities,
  denormalisedEntities,
} from '../../util/data';
import {
  resolveLatestProcessName,
  getProcess,
  isBookingProcess,
  SESSION_COURSE,
} from '../../transactions/transaction';

import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUserNotifications } from '../../ducks/user.duck';
import { onGetSubscriptionInvoices } from '../../util/api';
import { TRANSACTION_CANCELLED_BY_SCHOOL, TRANSACTION_CASH_LESSON_DECLINE } from '../../transactions/transactionProcessPurchase';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/NewOrderPage/SET_INITIAL_VALUES';

export const FETCH_TRANSACTION_REQUEST = 'app/NewOrderPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/NewOrderPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/NewOrderPage/FETCH_TRANSACTION_ERROR';

export const FETCH_TRANSITIONS_REQUEST = 'app/NewOrderPage/FETCH_TRANSITIONS_REQUEST';
export const FETCH_TRANSITIONS_SUCCESS = 'app/NewOrderPage/FETCH_TRANSITIONS_SUCCESS';
export const FETCH_TRANSITIONS_ERROR = 'app/NewOrderPage/FETCH_TRANSITIONS_ERROR';

export const TRANSITION_REQUEST = 'app/NewOrderPage/MARK_RECEIVED_REQUEST';
export const TRANSITION_SUCCESS = 'app/NewOrderPage/TRANSITION_SUCCESS';
export const TRANSITION_ERROR = 'app/NewOrderPage/TRANSITION_ERROR';

export const SUBSCRIPTION_INVOICES_REQUEST = 'app/NewOrderPage/SUBSCRIPTION_INVOICES_REQUEST';
export const SUBSCRIPTION_INVOICES_SUCCESS = 'app/NewOrderPage/SUBSCRIPTION_INVOICES_SUCCESS';
export const SUBSCRIPTION_INVOICES_ERROR = 'app/NewOrderPage/SUBSCRIPTION_INVOICES_ERROR';


// ================ Reducer ================ //

const initialState = {
  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  transactionRef: null,
  transitionInProgress: null,
  transitionError: null,
  subscriptionInvoiceProgress: false,
  subscriptionInvoiceError: null,
  previousInovices: null,
  upComingInoice: null
};

// Merge entity arrays using ids, so that conflicting items in newer array (b) overwrite old values (a).
// const a = [{ id: { uuid: 1 } }, { id: { uuid: 3 } }];
// const b = [{ id: : { uuid: 2 } }, { id: : { uuid: 1 } }];
// mergeEntityArrays(a, b)
// => [{ id: { uuid: 3 } }, { id: : { uuid: 2 } }, { id: : { uuid: 1 } }]
const mergeEntityArrays = (a, b) => {
  return a.filter(aEntity => !b.find(bEntity => aEntity.id.uuid === bEntity.id.uuid)).concat(b);
};

export default function transactionPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_TRANSACTION_REQUEST:
      return { ...state, fetchTransactionInProgress: true, fetchTransactionError: null };
    case FETCH_TRANSACTION_SUCCESS: {
      const transactionRef = { id: payload.data.data.id, type: 'transaction' };
      return { ...state, fetchTransactionInProgress: false, transactionRef };
    }
    case FETCH_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransactionInProgress: false, fetchTransactionError: payload };

    case FETCH_TRANSITIONS_REQUEST:
      return { ...state, fetchTransitionsInProgress: true, fetchTransitionsError: null };
    case FETCH_TRANSITIONS_SUCCESS:
      return { ...state, fetchTransitionsInProgress: false, processTransitions: payload };
    case FETCH_TRANSITIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransitionsInProgress: false, fetchTransitionsError: payload };

    case TRANSITION_REQUEST:
      return {
        ...state,
        transitionInProgress: payload,
        transitionError: null,
      };
    case TRANSITION_SUCCESS:
      return { ...state, transitionInProgress: null };
    case TRANSITION_ERROR:
      return {
        ...state,
        transitionInProgress: null,
        transitionError: payload,
      };

    case SUBSCRIPTION_INVOICES_REQUEST:
      return {
        ...state,
        subscriptionInvoiceProgress: true,
        subscriptionInvoiceError: null,
        previousInovices: null,
        upComingInoice: null
      };
    case SUBSCRIPTION_INVOICES_SUCCESS:
      return {
        ...state,
        previousInovices: payload.previousInovices,
        upComingInoice: payload.upComingInoice,
        subscriptionInvoiceProgress: false,
        subscriptionInvoiceError: null,
      };
    case SUBSCRIPTION_INVOICES_ERROR:
      return {
        ...state,
        subscriptionInvoiceProgress: false,
        subscriptionInvoiceError: true,
        previousInovices: null,
        upComingInoice: null
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const transitionInProgress = state => {
  return state.TransactionPage.transitionInProgress;
};

// ================ Action creators ================ //
export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
const fetchTransactionSuccess = response => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchTransactionError = e => ({ type: FETCH_TRANSACTION_ERROR, error: true, payload: e });

const fetchTransitionsRequest = () => ({ type: FETCH_TRANSITIONS_REQUEST });
const fetchTransitionsSuccess = response => ({
  type: FETCH_TRANSITIONS_SUCCESS,
  payload: response,
});
const fetchTransitionsError = e => ({ type: FETCH_TRANSITIONS_ERROR, error: true, payload: e });

const transitionRequest = transitionName => ({ type: TRANSITION_REQUEST, payload: transitionName });
const transitionSuccess = () => ({ type: TRANSITION_SUCCESS });
const transitionError = e => ({ type: TRANSITION_ERROR, error: true, payload: e });


export const subscriptionInVoicesRequest = () => ({
  type: SUBSCRIPTION_INVOICES_REQUEST,
})

export const subscriptionInVoicesSuccsess = (data) => ({
  type: SUBSCRIPTION_INVOICES_SUCCESS,
  payload: data,
})

export const subscriptionInVoicesError = () => ({
  type: SUBSCRIPTION_INVOICES_ERROR,
})

// ================ Thunks ================ //



// Helper to fetch correct image variants for different thunk calls
const getImageVariants = listingImageConfig => {
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = listingImageConfig;
  const aspectRatio = aspectHeight / aspectWidth;
  return {
    'fields.image': [
      // Profile images
      'variants.square-small',
      'variants.square-small2x',

      // Listing images:
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
  };
};

const listingRelationship = txResponse => {
  return txResponse.data.data.relationships.listing.data;
};


export const fetchTransaction = (id, txRole, config) => (dispatch, getState, sdk) => {
  try {

    dispatch(fetchTransactionRequest());
    dispatch(subscriptionInVoicesRequest());

    let txResponse = null;

    return sdk.transactions
      .show(
        {
          id,
          include: [
            'customer',
            'customer.profileImage',
            'provider',
            'provider.profileImage',
            'listing',
            'listing.currentStock',
            'booking',
            'reviews',
            'reviews.author',
            'reviews.subject',
          ],
          ...getImageVariants(config.layout.listingImage),
        },
        { expand: true }
      )
      .then(response => {
        txResponse = response;

        const listingId = listingRelationship(response).id;
        const entities = updatedEntities({}, response.data);
        const listingRef = { id: listingId, type: 'listing' };
        const transactionRef = { id, type: 'transaction' };
        const denormalised = denormalisedEntities(entities, [listingRef, transactionRef]);
        const listing = denormalised[0];
        const transaction = denormalised[1];
        const processName = resolveLatestProcessName(transaction.attributes.processName);

        const subcriptionId = processName == SESSION_COURSE && transaction && transaction.attributes && transaction.attributes.metadata && transaction.attributes.metadata.userSubscriptionData && transaction.attributes.metadata.userSubscriptionData.id;

        try {
          const process = getProcess(processName);
          const isInquiry = process.getState(transaction) === process.states.INQUIRY;

          // get the inVoices of prev and upcoming 
          if (subcriptionId) {
            try {
              onGetSubscriptionInvoices({ subcriptionId }).then((response) => {
                dispatch(subscriptionInVoicesSuccsess(response));
              }).catch((e) => dispatch(subscriptionInVoicesError()))
            } catch (e) {
              dispatch(subscriptionInVoicesError())
            }
          }

          // Fetch time slots for transactions that are in inquired state
          const canFetchTimeslots =
            txRole === 'customer' && isBookingProcess(processName) && isInquiry;

          if (canFetchTimeslots) {
            fetchMonthlyTimeSlots(dispatch, listing);
          }
        } catch (error) {
          console.log(`transaction process (${processName}) was not recognized`);
        }

        const canFetchListing = listing && listing.attributes && !listing.attributes.deleted;
        if (canFetchListing) {
          return sdk.listings.show({
            id: listingId,
            include: ['author', 'author.profileImage', 'images'],
            ...getImageVariants(config.layout.listingImage),
          });
        } else {
          return response;
        }
      })
      .then(async (response) => {

        const listingFields = config?.listing?.listingFields;
        const sanitizeConfig = { listingFields };

        dispatch(addMarketplaceEntities(txResponse, sanitizeConfig));
        dispatch(addMarketplaceEntities(response, sanitizeConfig));
        dispatch(fetchTransactionSuccess(txResponse));
        return response;
      })
      .catch(e => {
        console.log(e, '&&& &&& => e');

        dispatch(fetchTransactionError(storableError(e)));
        throw e;
      });
  } catch (e) {
    console.log(e, '&&& &&& => e');
  }
};

export const makeTransition = (txId, transitionName, params, updateMeta = false) => (dispatch, getState, sdk) => {
  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }
  dispatch(transitionRequest(transitionName));

  return sdk.transactions
    .transition({ id: txId, transition: transitionName, params }, { expand: true })
    .then(async (response) => {

      if (updateMeta && [TRANSACTION_CASH_LESSON_DECLINE,TRANSACTION_CANCELLED_BY_SCHOOL].includes(transitionName)) {
        const declineSessionPackages = await onDeclineUserPackages({ transactionId: txId.uuid });
        try {
          if (declineSessionPackages && declineSessionPackages.type && declineSessionPackages.type == "session" && declineSessionPackages.data && declineSessionPackages.data.length) {
            const declineSessionData = declineSessionPackages.data;
            for (let i = 0; i < declineSessionData.length; i++) {
              const { listingId, date } = declineSessionData[i].attributes;
              const currentSlotSeats = await sdk.timeslots.query({
                listingId: new UUID(listingId),
                start: moment().toDate(),
                end: moment().add(90, "days").toDate()
              });

              const slotDataIndex = currentSlotSeats.data.data.findIndex((st) => moment(st.attributes.start).format("DD-MM-YYYY") == date);
              if (slotDataIndex >= 0) {
                const slotData = currentSlotSeats.data.data[slotDataIndex];
                
                const { seats } = slotData.attributes;
                const newAvailabiltyException = { ...slotData.attributes, id:listingId, seats: seats + 1 };
                await onCreateAvalabityException(newAvailabiltyException);
              
              }
            }
          }
        } catch (e) {
          console.log(e, '&&& declineSessionPackages &&& => e');
        }
      }

      dispatch(addMarketplaceEntities(response));
      dispatch(transitionSuccess());
      dispatch(fetchCurrentUserNotifications());

      // There could be automatic transitions after this transition
      // For example mark-received-from-purchased > auto-complete.
      // Here, we make one delayed update to tx.
      // This way "leave a review" link should show up for the customer.
      window.setTimeout(() => {
        sdk.transactions.show({ id: txId }, { expand: true }).then(response => {
          dispatch(addMarketplaceEntities(response));
        });
      }, 3000);

      return response;
    })
    .catch(e => {
      dispatch(transitionError(storableError(e)));
      log.error(e, `${transitionName}-failed`, {
        txId,
        transition: transitionName,
      });
      throw e;
    });
};

const isNonEmpty = value => {
  return typeof value === 'object' || Array.isArray(value) ? !isEmpty(value) : !!value;
};

export const fetchNextTransitions = id => (dispatch, getState, sdk) => {
  dispatch(fetchTransitionsRequest());

  return sdk.processTransitions
    .query({ transactionId: id })
    .then(res => {
      dispatch(fetchTransitionsSuccess(res.data.data));
    })
    .catch(e => {
      dispatch(fetchTransitionsError(storableError(e)));
    });
};

// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = (params, search, config) => (dispatch, getState) => {
  const txId = new UUID(params.id);
  const state = getState().TransactionPage;
  const txRef = state.transactionRef;
  const txRole = params.transactionRole;

  // In case a transaction reference is found from a previous
  // data load -> clear the state. Otherwise keep the non-null
  // and non-empty values which may have been set from a previous page.
  const initialValues = txRef ? {} : pickBy(state, isNonEmpty);
  dispatch(setInitialValues(initialValues));

  // Sale / order (i.e. transaction entity in API)
  return Promise.all([
    dispatch(fetchTransaction(txId, txRole, config)),
    dispatch(fetchNextTransitions(txId)),
  ]);
};

